import React, { useEffect } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    BoxProps,
    Center,
    useBreakpointValue,
    HStack,
    Icon,
    Box,
    Spinner,
    Flex,
    Text
} from '@chakra-ui/react';
import { useTable, usePagination } from 'react-table';
import { Pager } from './Pager';
import { BsSortDown, BsSortUp, BsArrowDownUp } from 'react-icons/bs';

type SortableColumn = {
    Header: string;
    accessor: string;
    Cell?: (value: any) => React.ReactNode;
    sortKey?: string;
}

interface TableProps extends BoxProps {
    columns: SortableColumn[];
    data: any[];
    caption?: string;
    getRowProps?: (row: any) => any;
    onRowClick?: (row: any) => void;
    pageCount?: number;
    pageSize?: number;
    loading?: boolean;
    initialPage?: number;
    onPageChange?: (page: number) => void;
    sortColumn?: string;
    onSort?: (sortKey: string) => void;
}

const ObbiTable: React.FC<TableProps> = ({
    columns,
    data,
    caption,
    getRowProps,
    onRowClick,
    pageCount = 0,
    pageSize = 10,
    loading = false,
    initialPage = 0,
    onPageChange,
    sortColumn = 'datesignedoffdesc',
    onSort,
    ...boxProps
}) => {
    const isLoading = loading || data.length === 0;

    useEffect(() => {
        gotoPage(initialPage);
    }, [initialPage]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: { pageIndex },
        gotoPage
    } = useTable(
        {
            columns,
            data: data,
            initialState: {
                pageIndex: initialPage,
                pageSize
            },
            manualPagination: true,
            pageCount: pageCount
        },
        usePagination
    );

    const isMobile = useBreakpointValue({ base: true, md: false });

    const handlePageChange = (newPage: number) => {
        gotoPage(newPage);
        onPageChange?.(newPage);
    };
    
    const handleSort = (column: SortableColumn) => {
        if (!column.sortKey) return;

        const isCurrentSort = sortColumn.replace('desc', '') === column.sortKey;
        const isDescending = sortColumn.endsWith('desc');
        
        const newSort = isCurrentSort
            ? isDescending
                ? column.sortKey
                : `${column.sortKey}desc`
            : column.sortKey;

        onSort?.(newSort);
    };

    const getSortIcon = (column: SortableColumn) => {
        if (!column.sortKey) return null;

        const isCurrentSort = sortColumn.replace('desc', '') === column.sortKey;
        if (isCurrentSort) {
            const isDescending = sortColumn.endsWith('desc');
            return (
                <Icon 
                    as={isDescending ? BsSortDown : BsSortUp} 
                    color="gray.700"
                />
            );
        }
        
        return (
            <Icon 
                as={BsArrowDownUp} 
                color="gray.400" 
                fontSize="sm"
            />
        );
    };

    return (
        <Box position="relative" {...boxProps}>
            {isLoading && (
                <>
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        bg="blackAlpha.50"
                        zIndex={1}
                    />
                    <Flex
                        position="absolute"
                        top="3"
                        left="0"
                        right="0"
                        zIndex={2}
                        justify="center"
                    >
                        <Flex
                            direction="row"
                            align="center"
                            bg="white"
                            p={3}
                            borderRadius="md"
                            boxShadow="md"
                            gap={3}
                        >
                            <Spinner 
                                size="md" 
                                color="green.500" 
                                thickness="3px"
                                speed="0.8s"
                            />
                            <Text fontWeight="medium" color="gray.600">
                                Loading Reports...
                            </Text>
                        </Flex>
                    </Flex>
                </>
            )}
            <TableContainer
                p={3}
                border="1px solid"
                borderColor="gray.200"
                borderRadius="lg"
                overflow={isMobile ? "scroll" : "hidden"}
                opacity={isLoading ? 0.3 : 1}
                transition="opacity 0.2s"
            >
                <Table variant="unstyled" {...getTableProps()}>
                    {caption && <TableCaption>{caption}</TableCaption>}

                    <Thead>
                        {headerGroups.map(headerGroup => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => (
                                    <Th
                                        border="none"
                                        color="gray.700"
                                        fontWeight={600}
                                        fontSize="xs"
                                        cursor={column.sortKey ? "pointer" : "default"}
                                        onClick={() => handleSort(column)}
                                        _hover={{
                                            '& .sort-icon': {
                                                opacity: 1
                                            }
                                        }}
                                        {...column.getHeaderProps()}
                                    >
                                        <HStack spacing={2}>
                                            <span>{column.render('Header')}</span>
                                            <span className="sort-icon" style={{
                                                opacity: column.sortKey && !sortColumn.includes(column.sortKey) ? 0.8 : 1,
                                                transition: 'opacity 0.2s'
                                            }}>
                                                {getSortIcon(column)}
                                            </span>
                                        </HStack>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            const rowProps = getRowProps ? getRowProps(row) : {};

                            return (
                                <Tr
                                    {...row.getRowProps()}
                                    {...rowProps}
                                    key={i}
                                    className='hover-pop'
                                    cursor={isLoading ? "default" : "pointer"}
                                    onClick={() => !isLoading && onRowClick && onRowClick(row.original)}
                                >
                                    {row.cells.map(cell => (
                                        <Td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </Td>
                                    ))}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>

                <Center mt={4} mb={1}>
                    <Pager
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        setPageIndex={handlePageChange}
                        loading={isLoading}
                    />
                </Center>
            </TableContainer>
        </Box>
    );
};

export default ObbiTable;