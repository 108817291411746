import React, { Fragment, useState, useEffect } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { Box, HStack, Avatar, Text, Icon } from '@chakra-ui/react';
import { BsChevronDown, BsSearch, BsX } from 'react-icons/bs';
import { GenericComboboxProps } from './types/TaskReportingTypes';
import { isEmpty, isEqual } from 'lodash';

const GenericCombobox = <T,>({
    data,
    selectedItem,
    update,
    disabled = false,
    placeholder = 'Select an item',
    displayValue,
    getId,
    filterFunction
}: GenericComboboxProps<T>) => {
    const [selected, setSelected] = useState<T>(selectedItem);
    const [localQuery, setLocalQuery] = useState('');
    const [canScroll, setCanScroll] = useState(false);

    useEffect(() => {
        setSelected(selectedItem);
        setLocalQuery('');
    }, [selectedItem]);

    const filteredData = localQuery === ''
        ? data
        : data.filter((item: T) => filterFunction(item, localQuery));

    const updateItem = (item: T) => {
        setSelected(item);
        setLocalQuery('');
        update(item);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setLocalQuery(value);
    };

    const handleClear = (e: React.MouseEvent) => {
        e.stopPropagation();
        updateItem(data[0]);
    };

    const checkScrollable = (element: HTMLElement) => {
        if (element) {
            setCanScroll(element.scrollHeight > element.clientHeight);
        }
    };

    // Helper to check if current selection is the default value
    const isDefaultSelection = () => {
        if (isEmpty(selected)) return true;
        return isEqual(selected, data[0]);
    };

    return (
        <Combobox
            value={selected}
            disabled={disabled}
            onChange={updateItem}
        >
            <Box
                border='1px solid'
                borderColor={!disabled ? '#e2e8f0' : ''}
                rounded='lg'
                style={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}
                alignItems='center'
                justifyContent={'center'}
            >
                <Box mx={2} w='100%'>
                    <Combobox.Button
                        hidden={disabled}
                        style={{ width: '100%', textAlign: 'start' }}
                    >
                        <Combobox.Input
                            className='combobox'
                            autoComplete='off'
                            placeholder={placeholder}
                            displayValue={(item: T) => localQuery || displayValue(item)}
                            onChange={handleInputChange}
                            style={{
                                width: 'calc(100% - 30px)',
                                color: '#4a5568',
                                padding: '4px',
                                backgroundColor: 'transparent',
                                marginLeft: '5px'
                            }}
                        />
                        {!isDefaultSelection() ? (
                            <Icon
                                as={BsX}
                                mt={1.5}
                                color='gray.500'
                                float='right'
                                fontSize='xl'
                                onClick={handleClear}
                            />
                        ) : (
                            <Icon
                                as={BsChevronDown}
                                color='gray.500'
                                float='right'
                                mt={2}
                            />
                        )}
                    </Combobox.Button>
                </Box>
            </Box>
            <Transition
                as={Fragment}
                leave='transition ease-in duration-600'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
            >
                <Combobox.Options
                    as={Box}
                    disabled={disabled}
                    rounded='lg'
                    className={filteredData?.length > 5 ? 'always-show-scroll' : ''}
                    ref={(element: HTMLElement | null) => {
                        if (element) checkScrollable(element);
                    }}
                    style={{
                        border: '1px solid',
                        borderColor: 'rgba(48, 49, 51, 0.05)',
                        boxShadow: '0px 0px 10px rgba(48, 49, 51, 0.05)',
                        listStyle: 'none',
                        zIndex: 9999,
                        position: 'absolute',
                        backgroundColor: 'white',
                        width: '100%',
                        left: 0,
                        maxHeight: '200px',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        '-webkit-overflow-scrolling': 'touch'
                    }}
                >
                    {
                        filteredData?.length === 0 && localQuery !== '' ? (
                            <HStack
                                p={2}
                                bg='white'
                                rounded='md'
                                boxShadow='md'
                            >
                                <Icon as={BsSearch} color='gray.500' />
                                <Text
                                    fontSize='sm'
                                    cursor='pointer'
                                >
                                    Nothing found
                                </Text>
                            </HStack>
                        ) : (
                            <>
                                {filteredData?.map((item: T, i: number) => (
                                    <Combobox.Option key={i} value={item}>
                                        {({ active }) => (
                                            <HStack
                                                w='100%'
                                                p={2}
                                                bgColor={active ? 'gray.50' : ''}
                                                cursor='pointer'
                                            >
                                                <HStack>
                                                    {getId && getId(item) > 0 && (
                                                        <Avatar
                                                            size='xs'
                                                            name={displayValue(item)}
                                                            color='white'
                                                            verticalAlign='middle'
                                                        />
                                                    )}
                                                    <Text fontSize='sm'>{displayValue(item)}</Text>
                                                </HStack>
                                            </HStack>
                                        )}
                                    </Combobox.Option>
                                ))}
                                {canScroll && (
                                    <Box
                                        position='sticky'
                                        bottom={0}
                                        left={0}
                                        right={0}
                                        height='-webkit-fit-content'
                                        pointerEvents='none'
                                    />
                                )}
                            </>
                        )
                    }
                </Combobox.Options>
            </Transition>
        </Combobox>
    );
};

export default GenericCombobox;