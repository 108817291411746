import { get, getWithParams } from "../../api/axiosClient";

export async function getMyTeams() {
    let myTeams: any = {};

    await get(`admin/myTeams`)
        .then((res: any) => {
            myTeams = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return myTeams;
}

export async function getTeamMembers(id: Number) {
    let teamMembers: any = {};

    await getWithParams("admin/teamMembers", { id })
        .then((res: any) => {
            teamMembers = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return teamMembers;
}

export async function getMyUsers() {
    let myUsers: any = {};

    await get(`admin/myUsers`)
        .then((res: any) => {
            myUsers = res?.data?.users;
        })
        .catch((error: any) => {
            throw error;
        });

    return myUsers;
}