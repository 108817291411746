import React from 'react';
import { Box, VStack, HStack, Icon, Text } from '@chakra-ui/react';
import { BsX } from 'react-icons/bs';

export const SmallFilterCard: React.FC<any> = ({
	key,
	title,
	count,
	bgColor,
	icon,
	iconColor,
	onClickAction,
	selectedCard,
}) => {
	const isSelected = selectedCard === title;

	return (
		<Box
			key={key}
			w='fit-content'
			p={1.5}
			display='inline-block'
			textOverflow='ellipsis'
			className={isSelected ? 'hover-pop' : ''}
			cursor='pointer'
			rounded='lg'
			bgColor={isSelected ? 'white' : bgColor || 'white'}
			border='1px solid'
			borderColor={isSelected ? 'brand.500' : 'gray.200'}
			_hover={{ bgColor: isSelected ? '' : 'white' }}
			onClick={onClickAction}
		>
			<VStack color='gray.500' textOverflow='ellipsis'>
				<HStack textOverflow='ellipsis' alignSelf='normal'>
					{icon && (
						<Icon
							as={isSelected ? BsX : icon}
							color={isSelected ? 'green.600' : iconColor}
						/>
					)}
					<Text
						fontSize={{ base: 'xs', md: 'sm' }}
						fontWeight={500}
						color={isSelected ? 'green.600' : 'gray.600'}
					>
						{title}
					</Text>
				</HStack>
			</VStack>
		</Box>
	);
};